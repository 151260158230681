*, :before, :after {
  box-sizing: border-box;
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-size: 62.5%;
  line-height: 1.15;
}

body {
  margin: 0;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, -apple-system, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
}

button, input, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

:-moz-focusring {
  outline: 1px dotted ButtonText;
}

:-moz-ui-invalid {
  box-shadow: none;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
}

:root {
  --white: #fff;
  --white-dark: #f5f5f5;
  --gray: #b5b5b5;
  --gray-dark: #6c6c6c;
  --black-light: #1d1d1d;
  --black: #000;
  --main-light: #fde276;
  --main: #f6c90e;
  --main-dark: #947702;
  --standard-width: calc(100% - 10vw);
}

@keyframes background-pan {
  from {
    background-position: 0%;
  }

  to {
    background-position: -200%;
  }
}

@keyframes scale {
  from, to {
    transform: scale(0);
  }

  50% {
    transform: scale(1);
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(180deg);
  }
}

.Header:after, .Header:before {
  content: "";
  opacity: 0;
  z-index: 1;
  transition: all .5s ease-in-out;
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translateY(-50vh);
}

.Header:after {
  width: 0;
  height: 0;
  border-top: 0 solid rgba(0, 0, 0, 0);
  border-bottom: 45vh solid rgba(0, 0, 0, 0);
  border-left: 0 solid rgba(0, 0, 0, 0);
  border-right: 100vw solid #f6c90e;
  top: 5vh;
}

.Header:before {
  width: 100%;
  height: 5vh;
  background-color: #f6c90e;
  top: 0;
}

@media (min-width: 700px) {
  .Header:after, .Header:before {
    transform: translateY(0)translateX(45vw);
  }

  .Header:after {
    height: 100%;
    border-width: 0 0 100vh 40vw;
    border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #f6c90e;
    top: 0;
    right: 5vw;
  }

  .Header:before {
    width: 5vw;
    height: 100%;
  }
}

.Header.is-visible:after, .Header.is-visible:before {
  opacity: 1;
  transform: translateY(0)translateX(0);
}

a {
  color: var(--white-dark);
}

body {
  color: var(--white-dark);
  background-color: var(--black);
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
}

nav {
  display: none;
  position: fixed;
}

section {
  min-height: 100vh;
}

.Header {
  height: 100vh;
  max-height: -webkit-fill-available;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 5vw;
  display: flex;
  position: relative;
  overflow-x: hidden;
}

@media (min-width: 700px) {
  .Header {
    flex-direction: row;
    justify-content: space-between;
  }

  .Header__titleZone {
    max-width: 45vw;
  }
}

.Header h1 {
  text-align: center;
  opacity: 0;
  margin-bottom: 2vh;
  font-size: max(4rem, min(5vw, 8rem));
  font-weight: 700;
  line-height: 1.2;
  transition: all .3s ease-in-out;
  transform: translateY(1vh);
}

@media (min-width: 700px) {
  .Header h1 {
    text-align: left;
  }
}

.Header h2 {
  text-align: center;
  opacity: 0;
  margin-bottom: 2vh;
  font-size: max(2rem, min(3vw, 3rem));
  font-weight: 300;
  line-height: 1.2;
  transition: all .3s ease-in-out .1s;
  transform: translateY(1vh);
}

.Header h2 > .magic {
  display: inline-block;
  position: relative;
}

.Header h2 > .magic > .magic-star {
  --size: clamp(20px, 1.5vw, 30px);
  height: var(--size);
  left: var(--star-left);
  top: var(--star-top);
  width: var(--size);
  animation: scale .7s forwards;
  display: block;
  position: absolute;
}

.Header h2 > .magic > .magic-star > svg {
  opacity: .7;
  animation: rotate 1s linear infinite;
  display: block;
}

.Header h2 > .magic > .magic-star > svg > path {
  fill: var(--main-dark);
}

.Header h2 > .magic > .magic-text {
  background: linear-gradient(to right, var(--main), var(--main-dark), var(--main-light), var(--main));
  background-size: 200%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  white-space: nowrap;
  animation: background-pan 3s linear infinite;
}

.Header .magic-star {
  opacity: 0;
  transition: opacity .1s ease-in-out 1s;
}

.Header.is-visible .magic-star {
  opacity: 1;
}

@media (min-width: 700px) {
  .Header h2 {
    text-align: left;
  }
}

.Header h2 strong {
  color: var(--main);
}

.Header p {
  text-align: center;
  opacity: 0;
  font-size: max(1.6rem, min(2vw, 2rem));
  font-weight: 300;
  line-height: 1.2;
  transition: all .3s ease-in-out .2s;
  transform: translateY(1vh);
}

@media (min-width: 700px) {
  .Header p {
    text-align: left;
  }
}

.Header.is-visible h1, .Header.is-visible h2, .Header.is-visible p {
  opacity: 1;
  transform: translateY(0);
}

.Header__imgZone {
  z-index: 2;
  width: min(30vh, 300px);
  order: -1;
  margin-bottom: 8vh;
}

@media (min-width: 700px) {
  .Header__imgZone {
    width: min(70vh, 35vw);
    order: initial;
    margin-bottom: 0;
  }
}

.Header__idPicture {
  width: 100%;
  display: block;
  position: relative;
}

.Header__idPicture img {
  width: 100%;
  border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
}

.Header__idPicture:before {
  z-index: -1;
  content: "";
  background-color: var(--black-light);
  opacity: 0;
  border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
  transition: all .3s ease-in-out;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transform: translateX(0)translateY(0);
}

.Header.is-visible .Header__idPicture:before {
  opacity: .8;
  transform: translateX(-5%)translateY(5%);
}

.Header__scroll, .About__scroll {
  color: var(--main);
  cursor: pointer;
  opacity: 0;
  text-align: center;
  font-size: 1.4rem;
  font-weight: 700;
  transition: all 1s ease-in-out .6s;
  position: absolute;
  bottom: max(10vh, 64px);
  left: 50%;
  transform: translateX(-50%);
}

@media (min-width: 700px) {
  .Header__scroll, .About__scroll {
    opacity: 0;
    font-size: 2rem;
    bottom: max(12vh, 64px);
    left: 25%;
  }

  .About__scroll {
    bottom: max(10vh, 64px);
    left: 50%;
  }
}

.Header.is-visible .Header__scroll, .About__scroll.is-visible {
  opacity: 1;
  bottom: max(7vh, 40px);
}

@media (min-width: 700px) {
  .Header.is-visible .Header__scroll {
    opacity: 1;
    bottom: max(10vh, 40px);
  }
}

.Header__scroll:before, .About__scroll:before {
  content: "";
  width: 10px;
  height: 10px;
  border-bottom: 3px solid;
  border-right: 3px solid;
  display: block;
  position: absolute;
  bottom: -18px;
  left: 50%;
  transform: translate(-50%)rotate(45deg);
}

@media (min-width: 700px) {
  .Header__scroll:before, .About__scroll:before {
    width: 20px;
    height: 20px;
    border-bottom: 4px solid;
    border-right: 4px solid;
    bottom: -24px;
  }
}

.About {
  background-color: var(--black-light);
  position: relative;
}

.Expert__features {
  width: 100%;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
  margin-bottom: 32px;
  overflow-x: auto;
  overflow-y: hidden;
}

@media (min-width: 700px) {
  .Expert__features {
    overflow-x: hidden;
  }
}

.Expert__caroussel {
  flex-direction: column;
  display: flex;
}

@media (min-width: 700px) {
  .Expert__caroussel {
    width: 100%;
    flex-direction: row;
    justify-content: center;
  }
}

.About {
  padding: 5vh 0 10vh;
}

.About__intro {
  padding: 0 max(24px, 6vw);
  font-size: max(1.6rem, min(1.8vw, 2.5rem));
  font-weight: 300;
  line-height: 1.3;
}

.About__intro strong {
  color: var(--main);
}

.ExpertBlock {
  min-width: 70vw;
  background-color: var(--black);
  scroll-snap-align: center;
  opacity: .4;
  border-radius: 1rem;
  flex-direction: column;
  align-items: center;
  margin: 64px 32px 16px;
  transition: all 1s ease-in-out;
  display: flex;
  position: relative;
}

@media (min-width: 700px) {
  .ExpertBlock {
    min-width: auto;
    width: 25vw;
    margin: 72px 12px 24px;
  }
}

.ExpertBlock:nth-child(1) {
  transition-delay: 0s;
}

.ExpertBlock:nth-child(2) {
  transition-delay: .2s;
}

.ExpertBlock:nth-child(3) {
  transition-delay: .4s;
}

.Expert__features.is-visible .ExpertBlock {
  opacity: 1;
}

.ExpertBlock__icon {
  background-color: var(--black);
  border-radius: 50%;
  margin: max(4vh, 24px) 8px 8px;
  padding: 24px;
  display: block;
  position: absolute;
  top: -78px;
}

.ExpertBlock__svg {
  width: max(6vh, 48px);
}

.ExpertBlock__svg .svg1 {
  fill: var(--white-dark);
}

.ExpertBlock__svg .svg2 {
  fill: var(--gray);
}

.ExpertBlock__title {
  z-index: 1;
  text-align: center;
  color: var(--main);
  margin: 0;
  padding: 48px 12px 8px;
  font-size: 1.8rem;
  font-weight: 700;
}

.ExpertBlock__desc {
  text-align: center;
  margin: 0;
  padding: 8px 12px;
  font-size: 1.4rem;
  font-weight: 300;
}

.ExpertBlock__technos {
  max-width: calc(100% - 24px);
  margin: 8px 0 16px;
  padding: 0;
  list-style: none;
}

.ExpertBlock__technos li {
  opacity: 0;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
  transition: all 1s ease-in-out;
  display: flex;
  transform: translateY(-16px);
}

.ExpertBlock__technos li span:first-child {
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 50%;
  margin-right: 12px;
  font-size: 1.4rem;
  font-weight: 300;
  display: block;
  overflow: hidden;
}

.ExpertBlock__Stars {
  --percent: calc(var(--rating) / 5 * 100%);
  max-width: 50%;
  font-family: Times;
  font-size: 16px;
  line-height: 1;
  display: inline-block;
}

.Expert__features.is-visible .ExpertBlock__technos li, .Job__technos .ExpertBlock__technos li {
  opacity: 1;
  transform: translateY(0);
}

.Job__technos .ExpertBlock__technos {
  margin-bottom: 0;
}

.ExpertBlock__Stars:before {
  content: "★★★★★";
  letter-spacing: 3px;
  background: linear-gradient(90deg, var(--white) var(--percent), var(--gray-dark) var(--percent));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.Experience {
  flex-direction: column;
  padding: max(12px, 5vh) 0;
  display: flex;
}

.Experience__intro {
  z-index: 3;
  padding: 0 max(24px, 4vw) 2vh;
  font-size: max(1.6rem, min(1.3vw, 2.3rem));
  font-weight: 300;
  line-height: 1.2;
}

.Experience__intro a {
  color: var(--white-dark);
}

.Experience__intro h2 {
  margin-bottom: 8px;
  font-size: max(2rem, min(5vw, 5rem));
  font-weight: 700;
  line-height: 1.2;
}

.Experience__intro h3 {
  background-color: rgba(255, 255, 255, .3);
  border-radius: 1rem;
  padding: 4px 8px;
  font-size: max(1.4rem, min(1.4vw, 2.3rem));
  font-weight: 700;
  display: inline-block;
}

@media (min-width: 1200px) {
  .Experience {
    flex-direction: row;
  }

  .Experience__intro {
    max-width: 50vw;
  }
}

.Experience_groupRenault {
  background-color: var(--black);
  color: var(--white-dark);
  position: relative;
  overflow: hidden;
}

.Renault__logo {
  z-index: 0;
  fill: var(--white-dark);
  height: 140%;
  opacity: .2;
  position: absolute;
  transform: translate(-60%, -25%);
}

@media (min-width: 1200px) {
  .Renault__logo {
    height: 200vh;
    transform: translate(-25%, -25%);
  }
}

.Experience__screenshots {
  z-index: 1;
  width: 80vw;
  perspective: 350px;
  transform-style: preserve-3d;
  margin: auto;
}

.Experience__screenshotsGrid {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  grid-template-rows: repeat(7, 1fr);
  grid-template-columns: repeat(8, 1fr);
  display: grid;
}

.Experience__screenshot:nth-child(1) {
  grid-area: 1 / 1 / 4 / 4;
}

.Experience__screenshot:nth-child(2) {
  grid-area: 2 / 3 / 5 / 6;
}

.Experience__screenshot:nth-child(3) {
  grid-area: 1 / 5 / 4 / 8;
}

.Experience__screenshot:nth-child(4) {
  grid-area: 4 / 4 / 7 / 7;
}

.Experience__screenshot:nth-child(5) {
  grid-area: 5 / 2 / 8 / 5;
}

.Experience__screenshot:nth-child(6) {
  grid-area: 5 / 6 / 8 / 9;
}

.Experience__screenshot:nth-child(1) img {
  transition: transform .2s ease-in-out .5s;
}

.Experience__screenshot:nth-child(2) img {
  transition: transform .3s ease-in-out .6s;
}

.Experience__screenshot:nth-child(3) img {
  transition: transform .4s ease-in-out .7s;
}

.Experience__screenshot:nth-child(4) img {
  transition: transform .5s ease-in-out .8s;
}

.Experience__screenshot:nth-child(5) img {
  transition: transform .6s ease-in-out .9s;
}

.Experience__screenshot:nth-child(6) img {
  transition: transform .7s ease-in-out 1s;
}

.Experience__screenshot {
  aspect-ratio: 1280 / 800;
  z-index: 1;
  width: 100%;
  position: relative;
}

.Experience__screenshot img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  filter: grayscale(.5);
  transform: scale(.9);
}

.Experience__screenshot:hover {
  z-index: 2;
}

.Experience__screenshots.is-visible .Experience__screenshot img {
  transform: scale(1);
}

.Experience__screenshot:hover img {
  filter: grayscale(0);
  transition: transform .2s ease-in-out !important;
  transform: scale(1.2) !important;
}

@media (min-width: 1200px) {
  .Experience__screenshots {
    width: 40vw;
  }

  .Experience__screenshotsGrid {
    transform: rotateX(0deg)rotateZ(-5deg)rotateY(-10deg)translateX(-5vw)translateY(4vh)scale(1.5);
  }

  .Experience__screenshot:hover img {
    transform: scale(1.3);
  }
}

.PastExperience {
  background-color: var(--black-light);
  padding: max(12px, 5vh) 0;
}

.PastExperience h2 {
  width: var(--standard-width);
  margin: 0 auto;
  padding-bottom: 16px;
  font-size: max(2rem, min(5vw, 5rem));
  font-weight: 700;
  line-height: 1.2;
}

.Jobs {
  width: 100%;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
  overflow-x: auto;
  overflow-y: hidden;
}

.Jobs__caroussel {
  width: 260vw;
  flex-direction: row;
  justify-content: center;
  display: flex;
}

.Job {
  width: 80vw;
  background-color: var(--black);
  scroll-snap-align: center;
  opacity: .4;
  border-radius: 1rem;
  flex-direction: column;
  margin: 2vh auto;
  padding: 0;
  transition: all 1s ease-in-out;
  display: flex;
  transform: translateX(100%);
}

.Jobs.is-visible .Job {
  opacity: 1;
  transform: translateX(0);
}

.Job__main {
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.Job__logo {
  width: 100%;
  margin-bottom: 16px;
  position: relative;
}

.Job__logo img {
  width: 100%;
  aspect-ratio: 24 / 11;
  border-radius: 1rem 1rem 0 0;
}

.Job__description {
  width: 100%;
  flex: 1;
  padding: 0 16px 4px;
  position: relative;
}

.Job__company {
  color: var(--main);
  padding: 0;
  font-size: 2.2rem;
  font-weight: 700;
  display: block;
}

.Job__dates {
  background-color: var(--main);
  color: var(--black);
  border-radius: 6px;
  padding: 4px 10px;
  font-size: 1.3rem;
  font-weight: 700;
  display: inline-block;
  position: absolute;
  top: -30px;
  right: 6px;
}

.Job__technos {
  width: 95%;
  flex-direction: column;
  flex: 1;
  justify-content: flex-end;
  margin: 4px auto;
  display: flex !important;
}

.Job__technos .ExpertBlock__technos {
  border-top: 1px solid var(--black-light);
  padding-top: 12px;
}

@media (min-width: 700px) {
  .Jobs__caroussel {
    width: 135vw;
  }

  .Job {
    width: 45vw;
    margin: 12px;
    overflow-x: auto;
  }

  .PastExperience h2 {
    padding-bottom: 5vh;
  }
}

@media (min-width: 1200px) {
  .Jobs__caroussel {
    width: 100%;
  }

  .Job {
    width: 30vw;
    margin: 12px;
    overflow-x: hidden;
  }

  .PastExperience h2 {
    padding-bottom: 5vh;
  }
}

.Job__desc {
  color: var(--neutral-neutral);
  padding: 4px 0;
  font-size: 1.6rem;
  line-height: 1.3;
  display: block;
}

.Job__details {
  background: var(--neutral-lighter);
  border: 1px solid var(--neutral-light);
  border-radius: 6px;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  padding: 8px 16px;
  font-size: 1.3rem;
  line-height: 1.2;
  display: flex;
}

.Job__details > span {
  padding: 4px;
  font-size: 1.4rem;
  display: block;
}

.Job__detailsTitle {
  padding: 4px 0;
  font-weight: bold;
}

.Job__detailsDesc ul {
  margin: 0;
  padding: 0 0 0 20px;
}

.Job__detailsMore {
  z-index: 1;
  color: var(--white-dark);
  font-size: 1.2rem;
  position: absolute;
  bottom: 12px;
  left: 12px;
}

.Job__detailsMore a {
  color: currentColor;
}

.Job__detailsMore.is-white {
  color: var(--black);
}

.Socials {
  flex-direction: row;
  display: flex;
}

.Socials__link {
  width: 60px;
  display: block;
}

footer {
  background-color: var(--black);
  color: var(--gray);
  flex-direction: column;
  margin-top: 120px;
  padding: 2vh max(32px, min(5vw, 64px));
  font-size: max(2rem, min(1.5vw, 2.4rem));
  display: flex;
  position: relative;
}

footer a {
  color: var(--gray);
}

footer a:hover {
  color: var(--white);
}

footer svg path {
  fill: var(--gray);
}

footer a:hover svg path {
  fill: var(--white);
}

.Footer__diploma {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  display: flex;
}

.Footer__diplomaImg {
  width: 100%;
  filter: grayscale(.8);
  margin: 2vh 0;
}

.Footer__diplomaImg img {
  width: 100%;
  display: block;
}

.Footer__contact {
  margin-bottom: 2vh;
}

footer p {
  margin: 2vh 0;
  padding: 0;
}

.Footer__end {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  display: flex;
}

.Socials {
  min-width: 220px;
  flex-direction: row;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2vh;
  display: flex;
}

@media (min-width: 700px) {
  footer {
    flex-direction: row;
    padding-top: 5vh;
    padding-bottom: 5vh;
  }

  footer p {
    margin: 2vh 2vw;
    padding: 0;
  }

  .Footer__diploma {
    width: 33%;
    border-right: 1px solid var(--black-light);
  }

  .Footer__contact {
    width: 33%;
    border-right: 1px solid var(--black-light);
    margin-bottom: 0;
  }

  .Footer__end {
    width: 33%;
  }

  .Footer__diplomaImg {
    width: calc(100% - 4vw);
    margin: 0 2vw;
  }

  .Socials {
    margin: 2vh auto;
  }
}

.Footer__bg {
  pointer-events: none;
  background: var(--black-light);
  z-index: -1;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  transform: translateY(calc(1px - 100%));
}

.mountain, .mountain-two, .mountain-three {
  --base-value: 75px;
  border-left: calc(2 * var(--base-value)) solid transparent;
  border-right: calc(2 * var(--base-value)) solid transparent;
  border-bottom: calc(4 / 3 * var(--base-value)) solid var(--black);
  z-index: 1;
  position: absolute;
  bottom: 0;
}

.mountain-two {
  opacity: .3;
  z-index: 0;
  bottom: -20px;
  left: 110px;
}

.mountain-three {
  opacity: .5;
  z-index: 0;
  bottom: -10px;
  left: -75px;
}

.mountain-top {
  right: calc(-1 * var(--base-value));
  border-left: var(--base-value) solid transparent;
  border-right: var(--base-value) solid transparent;
  border-bottom: calc(2 / 3 * var(--base-value)) solid var(--gray-dark);
  z-index: 2;
  position: absolute;
}

.mountain-cap-1, .mountain-cap-2, .mountain-cap-3 {
  top: calc(2 / 3 * var(--base-value)  - 1px);
  border-left: 30px solid rgba(0, 0, 0, 0);
  border-right: 30px solid rgba(0, 0, 0, 0);
  border-top: 20px solid var(--gray-dark);
  position: absolute;
}

.mountain-cap-1 {
  left: calc(-1 * var(--base-value));
}

.mountain-cap-2 {
  left: -20px;
}

.mountain-cap-3 {
  border-right: 30px solid rgba(0, 0, 0, 0);
  border-top: 25px solid var(--gray-dark);
  left: 15px;
}

.cloud, .cloud:before, .cloud:after {
  width: 70px;
  height: 30px;
  background: #fff;
  border-radius: 100px / 50px;
  position: absolute;
}

.cloud {
  bottom: 50px;
}

.Footer__bg.is-visible .cloud {
  animation: cloud 10s linear infinite;
}

@keyframes cloud {
  0% {
    opacity: 0;
    left: -100px;
  }

  25% {
    opacity: 1;
  }

  75% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    left: 300px;
  }
}

.cloud:before {
  content: "";
  left: 50px;
}

.cloud:after {
  content: "";
  top: -10px;
  left: 25px;
}

/*# sourceMappingURL=index.e65d0a21.css.map */
